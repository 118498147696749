import axios from 'axios'
const httpClient = axios.create({
    baseURL: process.env.VUE_APP_CARDFARE_ENDPOINT,
    headers: {
        'Content-Type': 'application/json',
        "Accept": 'application/json',
    }
})


export default httpClient

