import ApiService from "./../services/api.service";
// state
const state = {}
// general getter
const getters = {}
// action
const actions = {
    getMeterTransactions(context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.setHeader()
            ApiService.get(`site/merchant/${payload.merchant}/meters/${payload.meter_code}/transactions/${payload.data}`,)
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    getMerchantTransactions(context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.setHeader()
            ApiService.get(`site/merchant/${payload.merchant}/transactions/${payload.data}`,)
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    getMerchantMeterTransactions(context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.setHeader()
            ApiService.get(`site/merchant/${payload.merchant}/transactions/meter_payment/${payload.data}`,)
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
}

// mutation
const mutations = {}

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
};

